<template>
  <div>
    <Modal :options="{width: '40vw', close: true}" @close="cerrar_modal">
      <form @submit.prevent="guardar_catalogo">
        <div class="title">{{ catalogo.id ? 'Editar catálogo' : 'Crear catálogo' }}</div>
        <div class="body">
          <div class="row form-group">
            <label for="nombre" class="col-form-label col-sm-2">Nombre</label>
            <div class="col-sm-10"><input v-model="cat.nombre" type="text" name="nombre" id="nombre" class="form-control"></div>
          </div>
          <div class="row form-group">
            <label for="codigo" class="col-form-label col-sm-2">Código</label>
            <div class="col-sm-10"><input v-model="cat.codigo" type="text" name="codigo" id="codigo" class="form-control"></div>
          </div>
          <div class="row form-group">
            <label for="descripcion" class="col-form-label col-sm-2">Descripción</label>
            <div class="col-sm-10"><input v-model="cat.descripcion" type="text" name="descripcion" id="descripcion" class="form-control"></div>
          </div>
          <div class="row form-group">
            <div class="col-sm-12 text-right">
              <button class="btn btn-info" type="button" @click="modal_nuevo=true">Agregar opción</button>
            </div>
          </div>

          <table class="opciones_tabla">
            <tr>
              <th>Nombre</th>
              <th>Valor</th>
              <th>Editar / Eliminar</th>
            </tr>
            <tr v-for="(opcion, index) in cat.opciones" :key="index">
              <td>{{ opcion.nombre }}</td>
              <td>{{ opcion.valor }}</td>
              <td>
                <img @click="editar_opcion(index, opcion)" class="quitar_etapa mr-2" src="https://static.bienparabien.com/constelacion/img/edit.png" alt="Editar etapa" title="Editar valor">
                <img @click="quitar_opcion(index)" class="quitar_etapa" src="https://static.bienparabien.com/constelacion/img/bin.png" alt="Elimitar valor" title="Elimitar valor">
              </td>
            </tr>
          </table>
        </div>
        <div class="footer">
          <div class="row">
            <div class="col-sm-12 text-right">
              <button class="btn btn-primary mr-2">Guardar catálogo</button>
              <button class="btn btn-danger" type="button" @click="cerrar_modal">Cancelar</button>
            </div>
          </div>
        </div>
      </form>
    </Modal>

    <Nuevo v-if="modal_nuevo" :opcion="opcion" @agregar="agregar_opcion" @close="cerrar_modal_nuevo" />
  </div>
</template>

<script type="text/javascript">
  import Modal from '@/components/Modal'
  import Nuevo from './Opcion/Nuevo'

  import api from './../../api/catalogos'

  export default {
    components: {
      Modal, Nuevo
    }
    ,props: {
      catalogo: {
        type: Object
        ,default: () => ({
          nombre: null
          ,codigo: null
          ,descripcion: null
          ,opciones: []
        })
      }
    }
    ,data: () => ({
      cat: {
        nombre: null
        ,codigo: null
        ,descripcion: null
        ,opciones: []
      }
      ,opcion: {
        nombre: null
        ,valor: null
      }
      ,opcion_index: null
      ,modal_nuevo: false
    })
    ,mounted: function() {
      if (this.catalogo.id)
        this.obtener_opciones();
    }
    ,methods: {
      obtener_opciones: async function() {
        try {
          this.cat = (await api.obtener_catalogo(this.catalogo.id)).data;
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,guardar_catalogo: async function() {
        try {
          if (this.cat.opciones.length == 0)
            return this.$helper.showMessage('Error','El catálogo debe tener al menos una opción','error','alert');

          let res = null;
          if (!this.cat.id)
            res = (await api.crear_catalogo(this.cat)).data
          else
            res = (await api.editar_catalogo(this.cat.id, this.cat)).data

          this.$log.info('guardar_catalogo',res);
          this.$emit('update');
        }catch(e) {
          this.$log.info('Error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,editar_opcion: function(index, opcion) {
        this.opcion_index = index;
        this.opcion = opcion;
        this.modal_nuevo = true;
      }
      ,quitar_opcion: function(index) {
        let opciones = [];

        for(let i=0; i<this.cat.opciones.length; i++) {
          if (index != i)
            opciones.push(this.cat.opciones[i]);
        }

        this.cat.opciones = opciones;
      }
      ,cerrar_modal: function() {
        this.$emit('close');  
      }
      ,agregar_opcion: function(opcion) {
        if (!this.opcion_index)
          this.cat.opciones.push(opcion);
        else {
          for(let i=0; i<this.cat.opciones.length; i++) {
            if (i == this.opcion_index)
              this.cat.opciones[i] = opcion;
          }

          this.opcion_index = null;
        }

        this.cerrar_modal_nuevo();
      }
      ,cerrar_modal_nuevo: function() {
        this.modal_nuevo = false;
        this.opcion = {
          nombre: null
          ,valor: null
        }
      }
    }
  }
</script>

<style lang="scss">
  .opciones_tabla {
    width: 100%;

    tr {
      th, td {
        padding: 5px 10px;
      }

      th {
        background-color: #404040;
        color: #fff;
      }

      th:nth-child(3) {
        width: 20%;
      }

      td:nth-child(3) {
        text-align: center;

        img {
          width: 20px;
          cursor: pointer;
        }
      }
    }

    tr:nth-child(2n+1) {
      background-color: #D9D9D9;
    }
  }
</style>